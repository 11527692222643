.page-title h1  {
    font-size: 18px !important;
}
.page-title h1 .subtitleText{
        font-size: 15px !important;
        width: 80% !important;
        margin-right: auto;
        margin-left: auto;
        text-align: center !important;
        font-weight: 900 !important;
}
.title{
    font-size: 12px !important;
}
.grid{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5px;
}
.btn-next{
    width: 30% !important;
    height: 60px !important;
    font-weight: bold !important;
    font-size: 15px !important;
}

  
  button[type="submit"],
  input[type="submit"] {
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    color: #fff;
    background-color: #3f51b5;
    margin-right: 8px;
    border-color: #3f51b5;
  }
  
  button[type="submit"]:hover,
  input[type="submit"]:hover {
    background: #3f51b5;
    cursor: pointer;
  }
  
  button[type="submit"]:active,
  input[type="button"]:active,
  input[type="submit"]:active {
    transition: 0.3s all;
    transform: translateY(3px);
    border: 1px solid transparent;
    opacity: 0.8;
  }
  
  input:disabled {
    opacity: 0.4;
  }
  
  input[type="button"]:hover {
    transition: 0.3s all;
  }
  
  button[type="submit"],
  input[type="button"],
  input[type="submit"] {
    -webkit-appearance: none;
  }
  
  .App {
    max-width: 600px;
    margin: 0 auto;
  }

  .unimedInput {
    border: 1px solid;
    height: 20px;
  }

  .unimedInput:focus {
    outline: 1px solid #3f51b5;
  }
  